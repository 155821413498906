body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  background: rgb(234, 237, 237);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/********** Start global rules **********/
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* fix button outline border on hover */
*:focus {
  outline: 0 !important;
}

html {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.error {
  color: red;
  font-size: 13px;
  font-weight: bold;
}

.btn {
  width: 100%;
  display: block;
  padding: 0.25rem 1.2rem;
  text-decoration: none;
  min-height: 2.4em;
  font: inherit;
  background: #f0c14b;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
}

.btn:hover,
.btn:active {
  background: #f4d078;
  background: -webkit-linear-gradient(top, #f7dfa5, #f0c14b);
  background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
}

.control {
  margin-bottom: 1rem;
  color: #1c1e21;
}

.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  color: #222;
}

.control label#filePicker-label,
.control textarea,
.control input {
  display: inline-flex;
  font-size: 1rem;
  height: 2.35em;
  justify-content: flex-start;
  line-height: normal;
  padding: 3px 7px;
  align-items: center;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 1px 0 rgb(255 255 255 / 50%), 0 1px 0 rgb(0 0 0 / 7%) inset;
  border: 1px solid #a6a6a6;
  border-top-color: #949494;
  transition: 0.4s;
}

.control textarea {
  height: 9.688rem;
  resize: none;
}

.control textarea:hover,
.control input:hover {
  outline: none;
  outline: 0 !important;
  border-color: #e77600;
  box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}

.control textarea:hover,
.control input:focus,
.control input:active {
  outline: 0 !important;
  border-color: #00695c;
  box-shadow: 0 0 3px 2px rgb(14 118 168/ 50%);
}

.control label#touched.invalid,
.control textarea.is-invalid,
.control textarea.touched.invalid,
.control input.is-invalid,
.control input.touched.invalid {
  border-color: red;
  background: #ffc2c2;
  box-shadow: none;
  transition: 0.3s;
}

.actions {
  margin-top: 1.5rem;
}
p.option {
  border-bottom: 1px solid #a6a6a6;
  text-align: center;
  font-weight: bold;
  margin-top: 1.813rem !important;
  font-size: 12.5px !important;
  line-height: 1.5 !important ;
}

.control label.error,
.error {
  width: 100%;
  color: red;
  /* color: #ff9700; */
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.footer-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.Layout {
  min-height: 300px;
}
